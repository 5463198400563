import '@/scss/auth/login.scss'
import '@/scss/lib/form.scss'
import { validateEmail, showErrors } from '@/ts/lib/form';

let submitting = false;

const form:HTMLFormElement = document.getElementById('login-form') as HTMLFormElement
const emailInput:HTMLInputElement = document.getElementById('field_email') as HTMLInputElement
const passwordInput:HTMLInputElement = document.getElementById('field_password') as HTMLInputElement

form.addEventListener('submit', (evt) => {
    evt.preventDefault();

    if(submitting){
        return;
    }

    const validEmail = validateEmail(emailInput.value)
    if(validEmail !== true){
        showErrors(emailInput, [validEmail])
    }

    const validPassword = passwordInput.value ? true : 'You have not entered a password'
    if(validPassword !== true){
        showErrors(passwordInput, [validPassword])
    }

    if(validEmail === true && validPassword === true){
        submitting = true;
        form.action = form.dataset.action!
        form.method = 'POST'
        ;(document.getElementById('login-form--submit') as HTMLButtonElement).disabled = true
        form.submit()
    }
})